import { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { AccordionDirType } from "#types/ResourceLinks";
import minusicon from "#images/icons/icon-minus.png";
import plusIcon from "#images/icons/icon-plus.png";
import "./Accordion.css";

function Accordion({
  data,
}: Readonly<{
  data: AccordionDirType;
}>) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const [state, setState] = useState(false);
  const btnOnClick = () => {
    setState(!state);
  };

  useEffect(() => {
    const contentEl = contentRef.current as HTMLDivElement;
    if (state) {
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [state]);

  return (
    <section id="accordion" className="accordion">
      {/* Each Title */}
      <button
        className={`category ${state ? "active" : ""}`}
        onClick={btnOnClick}
      >
        <div className="category-icon">
          <img
            className={"inner-label icon"}
            src={`${state ? minusicon : plusIcon}`}
            alt="Open List"
          ></img>
        </div>

        <div className="category-text">
          <div className="inner-label title">{data.title}</div>
        </div>
      </button>
      {/* Add links under title */}
      <div
        className="panel-resources"
        ref={contentRef}
        style={{ maxHeight: height }}
      >
        {data.content.map((item) => (
          <ul id="url" key={uuid()}>
            <li key={uuid()}>
              <a
                target="_blank"
                id="resource-url"
                key={uuid()}
                href={item.link}
              >
                {item.item}
              </a>
            </li>
          </ul>
        ))}
      </div>
    </section>
  );
}

export default Accordion;
