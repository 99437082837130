import MapETA from "#services/trolley-tracker/MapETA";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { apiURL } from "#components/ApiUrlConverter";
import "./ETAWidget.css";

const fetchTrolleyData = function (endpoint: string) {
  // Setup axios connection
  const api = axios.create({
    baseURL: endpoint,
    timeout: 1000,
  });

  // Attempt to handle 401 auth errors
  api.interceptors.response.use(undefined, (error) => {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(new Error(error));
  });

  // Retrive location/other trolley data
  const fetchData = async () => {
    const res = await api
      .get(`/`, {
        validateStatus: function (status) {
          return status < 300;
        },
      })
      .then((res) => {
        return res?.data;
      });
    return res;
  };

  return fetchData;
};

const ETAWidget = () => {
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  // endpoint for devmode
  const endpoint = devMode
    ? apiURL("/api/public/trolleytracker-dev")
    : apiURL("/api/public/trolleytracker");

  // Fetch trolley data on demand
  const { data: trolleyData } = useQuery(
    "trolleyData",
    fetchTrolleyData(endpoint),
    {
      cacheTime: 10000,
      staleTime: 30000,
      refetchInterval: 1000 * 10, // refreshes every 5 sec
    },
  );

  return (
    <div id="trolley-eta-widget">
      <div id="trolley-eta-widget__container">
        {trolleyData?.Data.length > 0 ? (
          <MapETA trolleysData={trolleyData.Data} />
        ) : (
          <div>
            <h3>Trolley Stop Estimate</h3>
            <hr />
            <h2>No Trolley info at this time</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ETAWidget;
