//
import { useEffect, useState } from "react";
import useDebounce from "#hooks/useDebounce";
import "./GlobalSearch.css";
import searchIcon from "#images/icons/search-icon.png";
import SearchResults from "./SearchResultData";

const GlobalSearch = () => {
  const [isInIframe, setIsInIframe] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // const [effects, setEffects] = useState<string[]>([]);

  useEffect(() => {
    // Check if the current window is different from the top-level window (iFrame detector)
    setIsInIframe(window.self !== window.top);
  }, []);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const searchPlaceholderTxt = "I'm looking for...";

  // Global search page
  return (
    <section className={`global-search iframe-` + Number(isInIframe)}>
      <h1 id="page-header">Looking for something?</h1>
      <div id="search-container">
        <div
          id="search-div"
          style={{ backgroundImage: "url(" + searchIcon + ")" }}
        >
          <input
            placeholder={searchPlaceholderTxt}
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
            id="queryText"
          />
        </div>

        <div id="displayFeatures">
          <SearchResults
            header="APU Home"
            source="home"
            searchValue={debouncedSearchValue}
          />
          <SearchResults
            header="Google"
            source="google"
            searchValue={debouncedSearchValue}
            showLoading={true}
          />
        </div>
      </div>
    </section>
  );
};

export default GlobalSearch;
