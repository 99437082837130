import { apiURL } from "#components/ApiUrlConverter";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import uuid from "react-uuid";
import { ResourceLinks } from "#types/ResourceLinks";
import { Link } from "react-router-dom";

// Function to fetch search results from internal api
const fetchResults = function (searchValue: string, source: string) {
  const endpoint = apiURL(
    source ? `/api/v1/search/${source}/` : `/api/v1/search/`,
  );
  // Setup axios connection
  const api = axios.create({
    baseURL: endpoint,
    timeout: 1000,
  });

  // Attempt to handle 401 auth errors
  api.interceptors.response.use(undefined, (error) => {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(new Error(error));
  });

  // Retrive results from api
  const fetchData = async () => {
    const res = await api
      .get(`/${searchValue}`, {
        validateStatus: function (status) {
          return status < 300;
        },
      })
      .then((res) => {
        return res?.data;
      });
    return res;
  };

  return fetchData;
};

// Return a limit of results and hide the rest with an option to show them
const ResultsLimit = ({
  source,
  searchData,
  numReslutsShown = 5,
  searchValue,
}: {
  source: string;
  searchData: ResourceLinks[];
  numReslutsShown?: number;
  searchValue: string;
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      {/* List out search results */}
      {searchData.map((result: ResourceLinks, index: number) => (
        <div
          key={uuid()}
          id="search-result"
          style={index < numReslutsShown || isActive ? {} : { display: "none" }}
        >
          <Link
            to={result?.link}
            onClick={() => {
              source != "google" &&
                saveQueryAnalytic(result?.link, result?.title, searchValue);
            }}
          >
            <div id="title">{result?.title}</div>
            {result?.snippet && <div id="snippet">{result?.snippet}</div>}
          </Link>
        </div>
      ))}

      {/* "Show More/Less" Button */}
      <button
        id={source.toLowerCase() + "-show-button"}
        className="show-more-less"
        onClick={handleClick}
        style={searchData.length > numReslutsShown ? {} : { display: "none" }}
      >
        <p>{isActive ? "Show Less" : "Show More"}</p>
      </button>
    </>
  );
};

// Record Analyitic data
const saveQueryAnalytic = (
  link: string,
  title: string,
  searchValue: string,
) => {
  const searchAnalyticsEndpoint = apiURL("/api/v1/search/analytics");
  // Format the data
  const analyticData = {
    title: title,
    link: link,
    query: searchValue,
  };
  // Post to internal api
  axios.post(searchAnalyticsEndpoint, analyticData);
};

const SearchResults = ({
  header,
  source = "",
  searchValue,
  showLoading = false,
}: {
  header?: string;
  source?: string;
  searchValue: string;
  showLoading?: boolean;
}) => {
  // Get reults from search
  const {
    data: searchData,
    isLoading,
    isSuccess,
  } = useQuery(source + searchValue, fetchResults(searchValue, source), {
    enabled: !!searchValue,
  });

  const loadingMsg = showLoading ? <p>Loading...</p> : <></>;

  // Conditionally show results
  let content = <></>;
  if (searchValue && (searchData || isLoading))
    content = (
      <>
        {/* Header div show if results */}
        <div
          id={source.toLowerCase() + "-results-header"}
          className="results-header"
          style={isSuccess ? { display: "block" } : { display: "none" }}
        >
          {header ? header + " Results" : "Results"}
        </div>
        {/* Search results */}
        <div id={source.toLowerCase() + "-results"} className="results">
          {isLoading ? (
            loadingMsg
          ) : (
            <ResultsLimit
              source={source.toLowerCase()}
              searchData={searchData}
              searchValue={searchValue}
            />
          )}
        </div>
      </>
    );

  return content;
};

export default SearchResults;
