import React, { useState, useEffect } from "react";
import MaptilerLeaflet from "./MaptilerLeaflet";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import ScreenSize from "#components/ScreenSize";
import "./TrolleyTracker.css";

import { APULogoObject } from "#components/APULogo";
import { apiURL } from "#components/ApiUrlConverter";

interface Counting {
  getTrolleyData: () => void;
  lastUpdatedTime: string;
}

let trolleyBannerSuccess = true;

// Timer Component
const Timer = (props: Counting) => {
  const [countUpdate, setCountUpdate] = useState(false);
  const [count, setCount] = useState(0);

  // Countdown timer to retrive updated data
  const updateTimer = () => {
    if (count <= 0) {
      setCount(5);
      // call back function to pull Trolley Data
      props.getTrolleyData();
    } else {
      setCount((currentCount) => currentCount - 1);
    }
    setTimeout(() => setCountUpdate(!countUpdate), 1000);
  };

  useEffect(() => {
    updateTimer();
  }, [countUpdate]);

  return (
    <div id="tracking-info">
      <div className="vert-bar">Updated {props.lastUpdatedTime}</div>
      <div>
        Refresh in{" "}
        {count.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
      </div>
    </div>
  );
};

// Banner
const TrolleyBanner = (props: { dev: boolean; status: boolean }) => {
  const [showBanner, setShowBanner] = useState(true);
  let bannerMessage = "";

  const now = new Date();

  // ---- Test banner (commented out)
  // Tuesday Gap
  // const now = new Date(2023, 10, 28);
  // now.setHours(15, 1, 0);
  // Weekend
  // const now = new Date(2023, 10, 25);
  // Weekday After Hours
  // const now = new Date(2023, 10, 22);
  // now.setHours(23, 1, 0);
  // Friday After Hours
  // const now = new Date(2023, 10, 24);
  // now.setHours(19, 1, 0);
  // ---- End Test Banner

  // Trolley Service Times:
  //  Monday-Thursday: 6:45am - 11:00pm
  //  Friday: 6:45am - 7:00pm
  //  No Trolley Service on Tuesday: 3:00pm - 3:45pm
  const beginHours = new Date(now);
  const afterHours = new Date(now);
  const afterFridayHours = new Date(now);
  const beginGapTuesday = new Date(now);
  const stopGapTuesday = new Date(now);
  // The % returns only the remainder after division (0 and 6 are the only two out of 0-6 without a remainder when divided by 6: 0 = Sun, 6 = Sat)
  const isWeekend = now.getDay() % 6 === 0;
  // Set hours
  beginHours.setHours(6, 45, 0);
  afterHours.setHours(23, 0, 0);
  afterFridayHours.setHours(19, 0, 0);
  beginGapTuesday.setHours(15, 0, 0);
  stopGapTuesday.setHours(15, 45, 0);

  // Set banner for Known down time
  // Test banner
  if (props.dev) {
    bannerMessage = `DEV MODE: ${now} Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`;
  } else if (!props.status) {
    bannerMessage = "Sorry! Trolley Tracker is currently out of service!";
    // Tuesday from 3:00pm - 3:45pm
  } else if (
    now.getDay() === 2 &&
    now > beginGapTuesday &&
    now < stopGapTuesday
  ) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will be back after 3:45pm!`;
    // Weekend or Friday evening
  } else if (isWeekend || (now.getDay() === 5 && now > afterFridayHours)) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will start Monday at 6:45am!`;
    // Weekday afterhours
  } else if (now > afterHours || now < beginHours) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`;
  }

  // Close if "x" is clicked on banner
  const closeAlertMessage = () => {
    setShowBanner(false);
  };

  return (
    <>
      {/* If there is no current message to show, message will be empty and the alert will not be shown */}
      {showBanner && bannerMessage.length > 0 && (
        <div className="custom-alert banner">
          <button
            id="closebtn"
            onClick={closeAlertMessage}
            onKeyDown={closeAlertMessage}
          >
            &#xD7;
          </button>
          <p>{bannerMessage}</p>
        </div>
      )}
    </>
  );
};

const zoomLevel = () => {
  const { width, height } = ScreenSize();
  const mapDefaultZoom = 15;

  // Set zoom based on Screen Size
  let mapZoom;
  if (width < 380 || height < 330) {
    mapZoom = 14.5;
  } else if ((width > 380 && width < 729) || height < 449) {
    mapZoom = mapDefaultZoom;
  } else {
    mapZoom = 15.5;
  }

  return mapZoom;
};

// Main Component
const TrolleyTracker = () => {
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  const devBanner = Boolean(page.get("banner") ?? false);

  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  // cosnt[trolleyDataSuccess, setTrolleyDataSuccess] = useState(true)

  const now = new Date();

  // endpoint for devmode
  const endpoint = devMode
    ? apiURL("/api/public/trolleytracker-dev")
    : apiURL("/api/public/trolleytracker");

  // Setup axios connection
  const api = axios.create({
    baseURL: endpoint,
    timeout: 1000,
  });

  // Attempt to handle 401 auth errors
  api.interceptors.response.use(undefined, (error) => {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(new Error(error));
  });

  // Retrive location/other trolley data
  const fetchTrolleyData = async () => {
    const res = await api
      .get(`/`, {
        validateStatus: function (status) {
          return status < 300;
        },
      })
      .then((res) => {
        return res.data;
      });
    return res;
  };

  // Fetch trolley data on demand
  const {
    data: trolleyData,
    isSuccess,
    isError,
    refetch,
  } = useQuery("trolleyData", fetchTrolleyData, {
    refetchOnWindowFocus: false,
    enabled: false, // disable this query from automatically running
  });

  // toggle only if Success or Error
  if (isSuccess) trolleyBannerSuccess = true;
  if (isError) trolleyBannerSuccess = false;

  // Handler function to refreash trolley data
  const handleTrolleyFetch = () => {
    // manually refetch
    refetch();
    setLastUpdatedTime(now.toLocaleTimeString());
  };

  return (
    <div id="trolley-tracker">
      {/* Conditionally show an alert banner-message with a $message string (currently undefined) */}
      {/* {message.length > 1 && message !== "HIDDEN" ? ( */}
      <TrolleyBanner dev={devBanner} status={trolleyBannerSuccess} />
      <img id="apu-logo" alt="APU Logo" src={APULogoObject.hzPNG} />
      {/* The div used by Leaflet and MapTiler to create the map */}
      <MaptilerLeaflet
        trolleysDataRaw={trolleyData}
        mapDefaultZoom={zoomLevel()}
      />
      {/* Additional information regarding trolley data and schedules */}
      <Timer
        getTrolleyData={handleTrolleyFetch}
        lastUpdatedTime={lastUpdatedTime}
      />
    </div>
  );
};

export default TrolleyTracker;
