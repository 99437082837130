import L, { LatLngBounds } from "leaflet";
import {
  ImageOverlay,
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import MapETA from "./MapETA";
import { TrolleyDataObject, TrolleyData } from "#types/Trolley";
import "./TrolleyTracker.css";

import ChristmasTheme, {
  chmasTrolleyIcon,
  chmasTrolleyIcon2,
} from "./ChristmasTheme";
import { hweenTrolleyIcon } from "./HolloweenTheme";
import easterRandomIcon from "./EasterTheme";
import { gradTrolleyIcon } from "./GraduationTheme";
import trolleyMap from "#images/maps/new_trolley_map_red_white_overlay.png";

interface TrolleysDataRaw {
  trolleysDataRaw?: {
    Data?: TrolleyDataObject[];
  };
  mapDefaultZoom: number;
}

interface TrolleysDataStyle extends TrolleyData {
  iconStyle: number;
  seasonEasterEgg: string;
}

interface CenterZoom {
  center: [number, number];
  zoom: number;
}

// Set Vars
const defaultIconWidth = 38;
// Set default theme
let mapStyle = "2a5bf32a-68eb-4b3d-a5e7-c86bbacaf844";
// Clear Easter Egg
let seasonEasterEgg = "none";

// General Functions
// Calculate easter (https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343)
const flr = (num: number) => Math.floor(num);
const getEaster = (year: number) => {
  // Golden Number - 1
  const gNum = year % 19;
  const cNum = flr(year / 100);
  // Related to Epact
  const hNum =
    (cNum - flr(cNum / 4) - flr((8 * cNum + 13) / 25) + 19 * gNum + 15) % 30;
  // Number of days from 21 March to the Paschal full moon
  const iNum =
    hNum - flr(hNum / 28) * (1 - flr(29 / (hNum + 1)) * flr((21 - gNum) / 11));
  // Weekday for the Paschal full moon
  const jNum = (year + flr(year / 4) + iNum + 2 - cNum + flr(cNum / 4)) % 7;
  // Number of days from 21 March to the Sunday on or before the Paschal full moon
  const lNum = iNum - jNum;
  const month = 3 + flr((lNum + 40) / 44);
  const day = lNum + 28 - 31 * flr(month / 4);

  // Return easter as a date object
  const thisEaster = new Date(year, month - 1, day);
  return thisEaster;
};

// Set Theme by season
const calcEasterEgg = (testMonth: number, testDate: number) => {
  // Show easter eggs for a given date range (month 0 = January)
  const today = new Date();
  if (testMonth) {
    today.setMonth(testMonth - 1);
    today.setDate(testDate);
  }

  // Add 3 weeks to easter for start date
  const thisEaster = new Date(getEaster(today.getFullYear()));
  const startEaster = new Date(getEaster(today.getFullYear()));
  startEaster.setDate(thisEaster.getDate() - 21);

  // Graduation Easter Egg icon 2 weeks before and 1 week after
  const startGrad = new Date(today.getFullYear(), 5 - 1, 4);
  const thisGrad = new Date(today.getFullYear(), 5 - 1, 4);
  startGrad.setDate(thisGrad.getDate() - 14);
  thisGrad.setDate(thisGrad.getDate() + 7);

  // Date based easter eggs
  if (today.getMonth() === 9) {
    // Show easter egg during October
    seasonEasterEgg = "Halloween";
    mapStyle = "b0ef78b4-0036-4dd4-830e-eeba31b8f1d2";
  } else if (today.getMonth() === 11) {
    // Show Christmas theme during December;
    seasonEasterEgg = "Christmas";
    mapStyle = "winter-v2";
    // Show Easter Theme during March
  } else if (today >= startEaster && today <= thisEaster) {
    seasonEasterEgg = "Easter";
    mapStyle = "511d9bf1-7e5c-4a8d-bd92-01d99ad339bb";
  } else if (today >= startGrad && today <= thisGrad) {
    seasonEasterEgg = "Graduation";
  }
};

// Return Trolly Marker icon
// Var - iconStyle
const getTrolleyIcon = (
  seasonEasterEgg: string,
  iconStyle: number,
  iconHex = "#3244c9",
) => {
  let iconWidth;
  // Default Icon as SVG
  let trolleyIcon = `<svg data-name="Layer 1" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>trolley_mark_COLOR</title>
        <circle cx="8.92" cy="9" r="6.5" fill="none" stroke="#fff" stroke-width="4px" />
        <circle cx="8.92" cy="9" r="6.5" stroke-miterlimit="10" fill=${iconHex} stroke="#000" />
        <circle cx="8.92" cy="9" r="2" />
      </svg>`;

  switch (seasonEasterEgg) {
    case "Halloween":
      trolleyIcon = hweenTrolleyIcon(iconHex);
      break;
    case "Christmas":
      if (iconStyle === 1) {
        trolleyIcon = chmasTrolleyIcon(iconHex);
      } else {
        trolleyIcon = chmasTrolleyIcon2(iconHex);
      }
      break;
    case "Easter":
      trolleyIcon = easterRandomIcon(iconHex);
      break;
    case "Graduation":
      trolleyIcon = gradTrolleyIcon(iconHex);
      iconWidth = 40;
      break;
  }

  iconWidth = iconWidth ?? defaultIconWidth;

  const trolleyMarkReg = L.divIcon({
    html: trolleyIcon,
    iconSize: [iconWidth, iconWidth],
    iconAnchor: [iconWidth / 2, iconWidth / 2],
    popupAnchor: [0, (iconWidth / 2) * -1],
  });

  return trolleyMarkReg;
};

// Trolley Marker Component
// Create Marker Trolley Marker on location
// Vars - seasonEasterEgg
// Add iconStyle to getTrolleyIcon
const MapMarker = (props: TrolleysDataStyle) => {
  // fill in the element
  const trolleyMarker = props.trolleysData.map(
    (trolley: {
      VehicleName: string;
      VehicleId: number;
      LastStatusLat: number;
      LastStatusLon: number;
      Color?: string;
      ColorHex?: string;
    }) => (
      <Marker
        // key={trolley.VehicleId}
        key={uuid()}
        position={[trolley.LastStatusLat, trolley.LastStatusLon]}
        icon={getTrolleyIcon(
          props.seasonEasterEgg,
          props.iconStyle,
          trolley.ColorHex,
        )}
      >
        <Popup>
          I&apos;m the <b>{trolley.Color}</b> trolley!
        </Popup>
      </Marker>
    ),
  );

  return <>{trolleyMarker}</>;
};

// Zoom/Center Component
// Set the Zoom and Center based on Screen
const MapCenterZoom = (props: CenterZoom) => {
  const map = useMap();
  const center = props.center;
  map.setView(center, props.zoom);
  return null;
};

// Main Component
// Vars - devMode snowColor
// Add iconStyle to MapMarker
const MaptilerLeaflet = (props: TrolleysDataRaw) => {
  // Get url params
  const [page, ,] = useSearchParams();
  const testMonth = Number(page.get("month"));
  const testDate = Number(page.get("day") ?? 15);
  const snowColor = Number(page.get("snow") ?? 0);
  const iconStyle = Number(page.get("icon") ?? 1);

  const trolleysData: TrolleyDataObject[] | undefined =
    props?.trolleysDataRaw?.Data;
  // maptiler token TODO move to env file
  const accessToken = "8eatdYNztRnd5H45doKA";

  // Get EasterEggs
  calcEasterEgg(testMonth, testDate);

  // Compile url for map
  const mapURL = `https://api.maptiler.com/maps/${mapStyle}/{z}/{x}/{y}.png?key=${accessToken}`;

  // The coordinates where the map will be centered on the screen.
  const mapCenterCoords: [number, number] = [34.13285, -117.8933];

  const imageBounds = new LatLngBounds(
    [34.13716, -117.90233],
    [34.12707, -117.88137],
  );

  return (
    <>
      <MapContainer
        id="map"
        className="map"
        zoomControl={false}
        scrollWheelZoom={false}
        zoomSnap={0.5}
        zoomDelta={0.5}
      >
        <MapCenterZoom center={mapCenterCoords} zoom={props.mapDefaultZoom} />
        <TileLayer
          attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapURL}
          tileSize={512}
          zoomOffset={-1}
          crossOrigin={true}
          minZoom={14}
          maxZoom={16}
        />
        <ImageOverlay url={trolleyMap} bounds={imageBounds} />

        {trolleysData && (
          <MapMarker
            trolleysData={trolleysData}
            seasonEasterEgg={seasonEasterEgg}
            iconStyle={iconStyle}
          />
        )}

        {/* SNOW */}
        {seasonEasterEgg === "Christmas" && (
          <ChristmasTheme snowColorID={snowColor} />
        )}
      </MapContainer>

      {trolleysData && <MapETA trolleysData={trolleysData} />}
    </>
  );
};

export default MaptilerLeaflet;
